import {
  createApp
} from "vue";
import App from "./app.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Notifications from "@kyvg/vue3-notification";
import moment from "moment";

import "bootstrap/dist/css/bootstrap.css";
import "@/assets/css/normalize.css";
import "@/assets/css/dashboard.css";
import "@/assets/css/app.custom.css";
import "bootstrap";

import "@/assets/css/nprogress.css";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import {
  library,
  dom
} from "@fortawesome/fontawesome-svg-core";
import {
  fas
} from "@fortawesome/free-solid-svg-icons";
import {
  far
} from "@fortawesome/free-regular-svg-icons";

library.add(fas, far);
dom.watch();
import VueMeta from "vue-meta";

import axios from '@/plugins/axios';
import loading from '@/plugins/loading-screen/loading';

const locale = i18n.global.locale.value;
import VueApexCharts from "vue3-apexcharts";

import ChartApex from "@/components/commons/ChartApex.vue"

createApp(App)
  .use(i18n)
  .use(store)
  .use(VueApexCharts)
  .use(router)
  .use(axios)
  .use(loading)
  .use(VueMeta)
  .use(Notifications)
  .component("fa", FontAwesomeIcon)
  .component("Datepicker", Datepicker)
  .component("ChartApex", ChartApex)
  .mount("#app");

moment.locale(locale);